import { Button, Col, Divider, List, Row, Spin, Upload, UploadProps } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import ApplicationPageProps from "common/models/ApplicationPageProps";
import { SaleMassUpdateClient } from "common/services/microservices/sales-client";
import React from "react";
import { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { authenticatedClient } from "core/components/AuthProvider";
import { InsightsClient, UserLogEvent } from "common/services/microservices/cron-boss-client";
import { CronTaskStateType } from "common/services/microservices/licenses-client";
import { waitFor } from "common/utilities/waitFor";

export default function SaleMassUpdateTool(props: ApplicationPageProps) {
  const [resultMessage, setResultMessage] = useState<UserLogEvent[]>();
  const [successCountMessage, setSuccessCount] = useState<UserLogEvent>();
  const [isSpinning, setSpinning] = React.useState<boolean>(false);

  let failureMessage = new UserLogEvent({ eventDatetime: new Date(), eventMessage: "Task failed to process report." });

  const goodBoyFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const uploadProps: UploadProps = {
    accept: goodBoyFileTypes.join(", "),
    multiple: false,
    maxCount: 1,
    onChange(info) {
      const { status } = info.file;

      if (status === "done") console.log("done");
      else if (status === "error") console.log("error");
    },
    customRequest: (options) => {
      setSpinning(true);
      authenticatedClient(MicroservicesEndpoint.sales, SaleMassUpdateClient)
        .then(async (c) => {
          let url = await c.getUploadUrl();
          let uploadRes = await fetch(url.presignedUrl, {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            method: "PUT",
            body: options.file,
          });

          if (!uploadRes.ok) {
            options.onError?.(undefined!, uploadRes.statusText);
            return;
          }

          let taskGuid = await c.processReport(url.objectGuid);
          options.onSuccess?.(uploadRes.statusText, undefined!);

          getUserLogs(taskGuid.guid);
        })
        .catch(() => {
          options.onError?.(undefined!, "Failed");
        });
    },
  };

  const getUserLogs = async (guid: string) => {
    authenticatedClient(MicroservicesEndpoint.cronboss, InsightsClient).then(async (c) => {
      let res = await c.getTaskReport(guid);
      console.log(res);
      await waitFor(async () => {
        let taskState = await c.getTaskReport(guid);
        if (taskState.cronTaskStateType == CronTaskStateType.Success) {
          setSpinning(false);
          setSuccessCount(taskState.userLogs.pop());
          setResultMessage(taskState.userLogs);
          return true;
        } else if (taskState.cronTaskStateType == CronTaskStateType.Failure) {
          setSpinning(false);
          setSuccessCount(failureMessage);
          setResultMessage(taskState.userLogs);
          return false;
        }
      });
    });
  };

  function downloadTemplateFile() {
    // Make sure to change filename each update to avoid user browser caches
    // not downloading the new file.
    var link = document.createElement("a");
    link.href = `/files/MassSaleUpdateTemplate2024-01-08.xlsx`;
    link.target = "_blank";
    link.download = "MassSaleUpdateTemplate.xlsx";
    link.click();
  }

  return (
    <Paragraph className="container my-3">
      <Row gutter={[16, 24]}>
        <Col offset={8}>
          <h3>Upload a Sale Mass Update Report</h3>
        </Col>
      </Row>
      <Row className="my-3" align="middle">
        <Col offset={1} span={10}>
          <p>
            Before uploading a report, please make sure the data has been transferred into the approved template for
            this tool.
          </p>
        </Col>
        <Col offset={4} span={4}>
          <Button type="primary" onClick={downloadTemplateFile}>
            Download Template
          </Button>
        </Col>
      </Row>
      <Divider></Divider>
      <Row gutter={[16, 24]} className="my-3">
        <Col offset={8} span={4}>
          Select a File
        </Col>
        <Col span={7}>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} style={{ width: "100% " }}>
              Browse
            </Button>
          </Upload>
        </Col>
      </Row>
      <Row justify="center">
        <Spin spinning={isSpinning}></Spin>
      </Row>
      {resultMessage && (
        <List
          size="small"
          header={<div>{successCountMessage?.eventMessage}</div>}
          bordered
          dataSource={resultMessage}
          renderItem={(item) => <List.Item>{item.eventMessage}</List.Item>}
        />
      )}
    </Paragraph>
  );
}
